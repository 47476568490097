<template>
  <div class="vouchers">
    <div v-if="portalPaymentsModel === 'OLD_MODEL' && this.files != null && this.files.length == 0" class="ml-4 mt-2 message-box">
      <div>
        There are no payment vouchers available.
      </div>
      <div v-if="navigationAllowed('reports')" class="pt-4">
        The payment register can be viewed with your <b-link to="/reports">Reports</b-link>.
      </div>
    </div>
    <div v-if="portalPaymentsModel === 'OLD_MODEL' && this.files != null && this.files.length > 0" class="ml-4">
      <div class="p-2 font-weight-bold">Most Recent</div>
      <div class="col-12">
        <div class="row">
          <div class="pb-3" align="center" v-for="recentFile in recentFiles" v-bind:key="recentFile.id">
            <div class="voucher-box mr-4">
              <div class="p-2 font-weight-bold">
                <!-- Create a date before passing to formatDate to avoid special handling of the +0000, which-->
                <!-- causes problems and I don't think we need anymore-->
                {{formatDate(new Date(recentFile.generatedDate), {hour: 'numeric', minute: 'numeric'})}}
              </div>
              <div v-if="recentFile.count != 1" class="p-2 font-weight-bold">
                {{recentFile.count}} payments
              </div>
              <div v-if="recentFile.count == 1" class="p-2 font-weight-bold">
                {{recentFile.count}} payment
              </div>
              <div class="p-2">
                <a :href="createFileLink(recentFile.id)">Download PDF</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pl-2 voucher-instructions">
        Please note that vouchers are provided for claims services administered by The Larkin Company; payments handled by
        external carriers are not included.
      </div>
      <div v-if="navigationAllowed('reports')" class="pl-2 pt-4 pb-3 voucher-instructions">
        The payment register can be viewed with your <b-link to="/reports">Reports</b-link>.
      </div>
      <div class="p-2"><b>View All</b>
        <b-dropdown :text="this.currentYear.toString()" variant="outline-secondary" class="m-md-2 pl-2">
          <b-dropdown-item v-for="year in this.dropdownYears" v-bind:key="year" @click="selectYear(year)">{{year}}</b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="voucher-enclosure-box">
        <table class="voucher-table">
          <thead>
            <tr>
              <th class="voucher-table-header">Date</th>
              <th class="voucher-table-header">Number of Payments</th>
              <th/>
            </tr>
          </thead>
          <tbody>
            <tr v-for="file in filteredFiles" v-bind:key="file.id">
              <td class="voucher-table-cell">
                {{formatDate(new Date(file.generatedDate), {hour: 'numeric', minute: 'numeric'})}}
              </td>
              <td class="voucher-table-cell">
                <div v-if="file.count != 1">
                  {{file.count}} payments
                </div>
                <div v-if="file.count == 1">
                  {{file.count}} payment
                </div>
              </td>
              <td class="voucher-table-cell">
                <a :href="createFileLink(file.id)">Download PDF</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="portalPaymentsModel !== 'OLD_MODEL'" class="voucher-enclosure-box">
      <div class="p-2">
        <b-dropdown v-if="this.currentYear" :text="this.currentYear.toString()" variant="outline-secondary" class="m-md-2 pl-2">
          <b-dropdown-item v-for="year in payrollBatchYears" v-bind:key="year" @click="selectYear(year)">{{year}}</b-dropdown-item>
        </b-dropdown>
      </div>
      <table class="voucher-table">
        <thead>
        <tr>
          <th class="voucher-table-header">Payroll Period</th>
          <th class="voucher-table-header">Batch Name</th>
          <th class="voucher-table-header">Last Modified</th>
          <th v-if="hasVouchers" class="voucher-table-header">Vouchers</th>
          <th v-if="hasCheckRegisters" class="voucher-table-header">Check Registers</th>
        </tr>
        </thead>
        <tbody v-for="payrollPeriod in payrollPeriods" v-bind:key="payrollPeriod">
          <tr>
            <th colspan="3" class="payroll-period-header">{{payrollPeriod}}</th>
            <th colspan="2" class="payroll-period-header"><a v-if="hasPayrollReconciliation(payrollPeriodBatches[payrollPeriod])" :href="createPayrollReconciliationLink(payrollPeriodBatches[payrollPeriod])">Payment 360
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                   viewBox="0 0 29.978 29.978" style="enable-background:new 0 0 29.978 29.978;" xml:space="preserve">
                <g>
                  <path d="M25.462,19.105v6.848H4.515v-6.848H0.489v8.861c0,1.111,0.9,2.012,2.016,2.012h24.967c1.115,0,2.016-0.9,2.016-2.012
                    v-8.861H25.462z"/>
                  <path d="M14.62,18.426l-5.764-6.965c0,0-0.877-0.828,0.074-0.828s3.248,0,3.248,0s0-0.557,0-1.416c0-2.449,0-6.906,0-8.723
                    c0,0-0.129-0.494,0.615-0.494c0.75,0,4.035,0,4.572,0c0.536,0,0.524,0.416,0.524,0.416c0,1.762,0,6.373,0,8.742
                    c0,0.768,0,1.266,0,1.266s1.842,0,2.998,0c1.154,0,0.285,0.867,0.285,0.867s-4.904,6.51-5.588,7.193
                    C15.092,18.979,14.62,18.426,14.62,18.426z"/>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                </svg>
            </a></th>
          </tr>
          <tr v-for="(batch, idx) in payrollPeriodBatches[payrollPeriod]" v-bind:key="batch.id">
            <td></td>
            <td class="voucher-table-cell">{{batch.name}}</td>
            <td class="voucher-table-cell">{{lastModifiedDate(batch)}}</td>
            <td v-if="hasVouchers" class="voucher-table-cell">
              <a class="download-link" :href="createPaymentBatchVoucherLink(batch.id)" target="_blank">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                   viewBox="0 0 29.978 29.978" style="enable-background:new 0 0 29.978 29.978;" xml:space="preserve">
                <g>
                  <path d="M25.462,19.105v6.848H4.515v-6.848H0.489v8.861c0,1.111,0.9,2.012,2.016,2.012h24.967c1.115,0,2.016-0.9,2.016-2.012
                    v-8.861H25.462z"/>
                  <path d="M14.62,18.426l-5.764-6.965c0,0-0.877-0.828,0.074-0.828s3.248,0,3.248,0s0-0.557,0-1.416c0-2.449,0-6.906,0-8.723
                    c0,0-0.129-0.494,0.615-0.494c0.75,0,4.035,0,4.572,0c0.536,0,0.524,0.416,0.524,0.416c0,1.762,0,6.373,0,8.742
                    c0,0.768,0,1.266,0,1.266s1.842,0,2.998,0c1.154,0,0.285,0.867,0.285,0.867s-4.904,6.51-5.588,7.193
                    C15.092,18.979,14.62,18.426,14.62,18.426z"/>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                </g>
                                <g>
                </g>
                                <g>
                </g>
                                <g>
                </g>
                                <g>
                </g>
                                <g>
                </g>
                                <g>
                </g>
                                <g>
                </g>
                                <g>
                </g>
                                <g>
                </g>
                                <g>
                </g>
                                <g>
                </g>
                                <g>
                </g>
                                <g>
                </g>
                                <g>
                </g>
                                <g>
                </g>
                </svg>
              </a>
<!--              <a v-if="batch.achCount != null" href="#" class="download-link" target="_blank">ACH</a>-->
            </td>
            <td v-if="hasCheckRegisters" class="voucher-table-cell">
              <select v-model="selectedCheckRegisterFile">
                <option></option>
                <option v-for="file in batch.checkRegisters" v-bind:key="file.id" v-bind:value="file.id">{{file.label}}</option>
              </select>
              <a class="download-link" :class="{ 'interaction-disabled': !selectedCheckRegisterFile }" :href="createPaymentBatchCheckRegisterLink(batch.id, selectedCheckRegisterFile)">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                     viewBox="0 0 29.978 29.978" style="enable-background:new 0 0 29.978 29.978;" xml:space="preserve">
                <g>
                  <path d="M25.462,19.105v6.848H4.515v-6.848H0.489v8.861c0,1.111,0.9,2.012,2.016,2.012h24.967c1.115,0,2.016-0.9,2.016-2.012
                    v-8.861H25.462z"/>
                  <path d="M14.62,18.426l-5.764-6.965c0,0-0.877-0.828,0.074-0.828s3.248,0,3.248,0s0-0.557,0-1.416c0-2.449,0-6.906,0-8.723
                    c0,0-0.129-0.494,0.615-0.494c0.75,0,4.035,0,4.572,0c0.536,0,0.524,0.416,0.524,0.416c0,1.762,0,6.373,0,8.742
                    c0,0.768,0,1.266,0,1.266s1.842,0,2.998,0c1.154,0,0.285,0.867,0.285,0.867s-4.904,6.51-5.588,7.193
                    C15.092,18.979,14.62,18.426,14.62,18.426z"/>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                </g>
                  <g>
                </g>
                  <g>
                </g>
                  <g>
                </g>
                  <g>
                </g>
                  <g>
                </g>
                  <g>
                </g>
                  <g>
                </g>
                  <g>
                </g>
                  <g>
                </g>
                  <g>
                </g>
                  <g>
                </g>
                  <g>
                </g>
                  <g>
                </g>
                  <g>
                </g>
                  <g>
                </g>
                </svg>
              </a>
           </td>
          </tr>
        </tbody>
        <tr v-for="file in filteredFiles" v-bind:key="file.id">
          <td class="voucher-table-cell">
            {{formatDate(new Date(file.generatedDate), {hour: 'numeric', minute: 'numeric'})}}
          </td>
          <td class="voucher-table-cell">
            <div v-if="file.count != 1">
              {{file.count}} payments
            </div>
            <div v-if="file.count == 1">
              {{file.count}} payment
            </div>
          </td>
          <td class="voucher-table-cell"> N/A</td>
          <td class="voucher-table-cell">
            <a :href="createFileLink(file.id)">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                   viewBox="0 0 29.978 29.978" style="enable-background:new 0 0 29.978 29.978;" xml:space="preserve">
                <g>
                  <path d="M25.462,19.105v6.848H4.515v-6.848H0.489v8.861c0,1.111,0.9,2.012,2.016,2.012h24.967c1.115,0,2.016-0.9,2.016-2.012
                    v-8.861H25.462z"/>
                  <path d="M14.62,18.426l-5.764-6.965c0,0-0.877-0.828,0.074-0.828s3.248,0,3.248,0s0-0.557,0-1.416c0-2.449,0-6.906,0-8.723
                    c0,0-0.129-0.494,0.615-0.494c0.75,0,4.035,0,4.572,0c0.536,0,0.524,0.416,0.524,0.416c0,1.762,0,6.373,0,8.742
                    c0,0.768,0,1.266,0,1.266s1.842,0,2.998,0c1.154,0,0.285,0.867,0.285,0.867s-4.904,6.51-5.588,7.193
                    C15.092,18.979,14.62,18.426,14.62,18.426z"/>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                </svg>
              </a>
          </td>
          <td v-if="hasCheckRegisters" class="voucher-table-cell"></td>
        </tr>
        </tbody>
      </table>
    </div>

  </div>
</template>

<script>
import ApiProperties from '../../../src/main/resources/application';
import dateMixin from '@/mixins/dateMixin.js';
import navigationMixin from '@/mixins/navigationMixin.js';

  export default {
    mixins: [dateMixin, navigationMixin],

    data: function() {
      return {
        files: null,
        currentYear: null,
        paymentBatches: [],
        fields: [
          {key: 'date', formatter: value => {
              return this.formatVoucherDate(value);
            }},
          {key: 'count'},
          {key: 'action'},
        ],
        checkRegisterFormats: [
          { key: 1, name: 'Default Check Register Format With Taxes' },
          { key: 2, name: 'EIB - Payment by payroll period' },
          { key: 3, name: 'EIB - Full Payment' }
        ],
        selectedCheckRegisterFile: null,
        portalPaymentsModel: 'OLD_MODEL'
      }
    },

    computed: {
      recentFiles() {
        let recents = [];
        for (let i = 0; i < 3 && i < this.files.length; i++) {
          recents[i] = this.files[i];
        }
        return recents;
      },
      dropdownYears() {
        return this.getDropdownYears();
      },
      filteredFiles() {
        if (this.currentYear == null) {
          return this.files;
        }
        return this.getFilteredPaymentVouchers(this.currentYear);
      },
      filteredPaymentBatches() {
        if (this.currentYear == null) {
          return this.paymentBatches;
        }

        return this.paymentBatches.filter(pb => {
          const date = new Date(pb.payrollSchedulePeriod.start);

          return date.getFullYear() === this.currentYear;
        });
      },
      payrollPeriodBatches() {
        let payrollPeriods = {};

        this.filteredPaymentBatches.forEach(pb => {
          const payrollPeriod = `${pb.payrollSchedulePeriod.start} - ${pb.payrollSchedulePeriod.end} (${pb.payrollScheduleName})`;

          if (!payrollPeriods.hasOwnProperty(payrollPeriod)) {
            payrollPeriods[payrollPeriod] = [];
          }

          payrollPeriods[payrollPeriod].push(pb);
        });

        return payrollPeriods;
      },
      payrollPeriods() {
        const keys = Object.keys(this.payrollPeriodBatches);

        return keys
            .map(k => {
              const re = /^(.*) - (.*) \((.*)\)$/;
              const matches = k.match(re);

              const start = matches[1] || "";
              const end = matches[2] || "";
              const payrollScheduleName = matches[3] || "";

              return [start, end, payrollScheduleName];
            })
            .sort((a, b) => {
              // Sort by start date (reverse chronological), then by end date (reverse chronological)
              // then by payroll schedule name (alphabetical)
              return b[0] - a[0] || b[1] - a[1] || a[2] - b[2];
            })
            .map(el => {
              return `${el[0]} - ${el[1]} (${el[2]})`;
            });
      },
      payrollBatchYears() {
        return [...new Set([...this.getDropdownYears(), ...this.paymentBatches
            .map(pb => {
              const date = new Date(pb.payrollSchedulePeriod.start);

              return date.getFullYear();
            })
            .sort((a, b) => {
              if (a < b) return 1;
              if (a > b) return -1;
              return 0;
            })])];
      },
      hasVouchers() {
        return ['VOUCHERS_ONLY', 'VOUCHERS_AND_CHECK_REGISTERS'].includes(this.portalPaymentsModel);
      },
      hasCheckRegisters() {
        return this.portalPaymentsModel === 'VOUCHERS_AND_CHECK_REGISTERS';
      }
    },
    methods: {
      formatVoucherDate: function(date) {
        return this.formatDate(new Date(date), {hour: 'numeric', minute: 'numeric'});
      },
      getYearFromDateString: function(dateString) {
        return new Date(dateString).getFullYear();
      },
      getDropdownYears: function() {
        if (this.files.length === 0) {
          return [];
        }

        let minYear = this.getYearFromDateString(this.files[this.files.length - 1].generatedDate);
        let maxYear = this.getYearFromDateString(this.files[0].generatedDate);
        let years = [];
        let index = 0;
        for (let year = minYear; year <= maxYear; year++) {
          years[index] = year;
          index++;
        }
        return years;
      },
      getFilteredPaymentVouchers: function(year) {
        let filtered = [];
        let index = 0;
        for (let i = 0; i < this.files.length; i++) {
          if (this.getYearFromDateString(this.files[i].generatedDate) == year) {
            filtered[index] = this.files[i];
            index++;
          }
        }
        return filtered;
      },
      getPaymentVouchers: function() {
        let url = ApiProperties.api.paymentVoucher;

        this.$http.get(url).then(response => {
          this.processPaymentVouchers(response.data);
        }).catch(e => {
          console.log('error', e);
        })
      },
      processPaymentVouchers: function(allVouchers) {
        console.log("voucher count: " + allVouchers.files.length);
        for (let i = 0; i < allVouchers.files.length; i++) {
          let voucher = allVouchers.files[i];
          console.log(voucher.name);
        }
        this.files = allVouchers.files;
        if (this.files.length > 0) {
          this.currentYear = this.getYearFromDateString(this.files[0].generatedDate);
        }
      },
      getPaymentBatches: function() {
        let url = ApiProperties.api.paymentBatchList;

        this.$http.get(url).then(response => {
          this.paymentBatches = response.data;
          if (this.paymentBatches.length === 0) return;
          const pb = this.paymentBatches[0];
          this.portalPaymentsModel = pb.portalPaymentsModel;

          // If there are no legacy payments the current year will be unavailable
          if (!this.currentYear) {
            this.currentYear = this.payrollBatchYears.at(0);
            console.log('no currentYear set', this.payrollBatchYears, this.currentYear);
          }
          else {
            this.currentYear = this.payrollBatchYears.find(year => year >= this.currentYear)
            console.log('currentYear set', this.payrollBatchYears, this.currentYear);
          }
        }).catch(e => {
          console.log('error', e);
        })
      },
      createFileLink: function (id) {
        return process.env.VUE_APP_AXIOS_BASE + ApiProperties.api.paymentVoucherFileById.replace(`{id}`, id);
      },
      createPaymentBatchVoucherLink: function (paymentBatchId) {
        return process.env.VUE_APP_AXIOS_BASE + ApiProperties.api.paymentBatchVoucher.replace(`{paymentBatchId}`, paymentBatchId);
      },
      createPaymentBatchCheckRegisterLink: function (paymentBatchId, fileId) {
        return process.env.VUE_APP_AXIOS_BASE + ApiProperties.api.paymentBatchCheckRegister.replace(`{paymentBatchId}`, paymentBatchId).replace(`{fileId}`, fileId);
      },
      createPayrollReconciliationLink: function (paymentBatches) {
        const pb = paymentBatches[0];

        if (!pb) {
          return "#";
        }

        return process.env.VUE_APP_AXIOS_BASE + ApiProperties.api.paymentReconciliationFile.replace(`{paymentBatchId}`, pb.id);
      },
      hasPayrollReconciliation: function (paymentBatches) {
        const pb = paymentBatches[0];

        if (!pb) {
          return false;
        }

        return pb.payrollSchedulePeriod.paymentReconciliationFileAvailable;
      },
      selectYear: function(year) {
        this.currentYear = year;
      },
      lastModifiedDate: function(batch) {
        let lastModified = batch.vouchersGeneratedDate;

        if (!lastModified || batch.checksGeneratedDate && batch.checksGeneratedDate < lastModified) {
          lastModified = batch.checksGeneratedDate;
        }

        if (!lastModified || batch.achGeneratedDate && batch.achGeneratedDate < lastModified) {
          lastModified = batch.achGeneratedDate;
        }

        if (!lastModified || batch.checkRegistryGeneratedDate && batch.checkRegistryGeneratedDate < lastModified) {
          lastModified = batch.checkRegistryGeneratedDate;
        }

        if (lastModified) {
          return this.formatVoucherDate(lastModified);
        }

        return '';
      }
    },
    created: function () {
      this.getPaymentVouchers();
      this.getPaymentBatches();
    },
  }

</script>
<style lang="scss">
@import "../scss/variables";

.iframe-container {
  overflow: auto;
  /* 16:9 aspect ratio */
  padding-top: 56.25%;
  position: relative;
  background-image: url('../../public/img/spinner.gif');
  background-position: center;
  background-repeat: no-repeat;
}

.iframe-container iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background: transparent;
}

.voucher-table {
  //width: 900px;
}

.voucher-instructions {
  width: 900px;
}

.register-instructions {
  width: 900px;
  font-size: .8em;
}

.voucher-enclosure-box {
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #CACACA;
  border-radius: 6px;
  padding: 25px 30px;
  //width: 900px;
}

.voucher-table-cell {
  //padding-bottom: 10px;
  //padding-top: 10px;
  padding: 10px;
  text-align: center;
}

.voucher-table-header {
  //padding-bottom: 20px;
  padding: 0 10px 20px 10px;
  font-size: 0.8rem;
  text-transform: uppercase;
}

.payroll-period-header {
  padding: 4px 8px;
  background: #c3c3c3;
  height: 4rem;
  margin: 1rem 0;
}

.payroll-period-header svg {
  margin: 10px;
}

.payroll-period-header > a,
.payroll-period-header > a:hover {
  cursor: pointer;
  display: inline-block;
  padding: 4px 8px;
  background: #fff;
  color: $global-dark-100;
}

tbody:after {
  content: ' ';
  display: block;
  height: 2rem;
}

.voucher-box {
  box-sizing: border-box;
  background: #fff;
  border: 2px solid;
  border-radius: 12px;
  padding: 25px 30px;
  border-color: $sideNavBackGround;
}

.nav-link {
  &.active {
    &:after {
      z-index: 0 !important;
    }
  }
}

.message-box {
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #CACACA;
  border-radius: 6px;
  padding: 25px 30px;
  max-width: 600px;
}

.vouchers {
  color: #2c2e30;
  font-size: .8em;
  min-width: 1020px;
}

.download-link {
  padding-left: 10px;
  padding-right: 10px;
}

.download-link:last-of-type {
  border-right: none;
}

.interaction-disabled {
  pointer-events: none;
  opacity: 30%;
}
</style>
